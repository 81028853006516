<template>
  <CritereBase :selected="selected" @select="(value) => $emit('select', value)">
    Tiers apporteur
  </CritereBase>
</template>

<script>
import CritereBase from "./CritereBase.vue";
import { props, methods } from "./shared";
export default {
  name: "CritereApporteur",
  components: { CritereBase },
  props: { ...props },
  methods: { ...methods },
};
</script>
