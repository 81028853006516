<template>
  <CritereBase :selected="selected" @select="(value) => $emit('select', value)">
    <CustomInputDateComponent
      label="Date d'adhésion entre le"
      :labelCols="{ md: '6' }"
      :value="params[0].valeur"
      @input="(value) => inputParam(0, value)"
    ></CustomInputDateComponent>
    <CustomInputDateComponent
      label="et le"
      :labelCols="{ md: '6' }"
      :value="params[1].valeur"
      @input="(value) => inputParam(1, value)"
    ></CustomInputDateComponent>
  </CritereBase>
</template>

<script>
import CritereBase from "./CritereBase.vue";
import CustomInputDateComponent from "../../inputs/CustomInputDateComponent.vue";
import { props, methods } from "./shared";
export default {
  name: "CritereDateAdhesion",
  components: { CritereBase, CustomInputDateComponent },
  props: { ...props },
  methods: { ...methods },
};
</script>
