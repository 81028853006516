<template>
  <b-modal ref="modal" scrollable @close="cancel">
    <template #modal-title>
      <template>Revue des destinataires</template>
    </template>
    <b-container v-for="(t, i) in tiers" :key="t.codeTiers">
      <b-row :class="i === 0 ? '' : 'mt-3'">
        <b-col cols="auto">
          <b-form-checkbox
            :disabled="t.disabled"
            :checked="t.selected"
            @input="
              (value) => {
                t.selected = value;
                t.contacts.forEach((c) => (c.selected = value));
              }
            "
          ></b-form-checkbox>
        </b-col>
        <b-col>
          {{ t.libelle }}
        </b-col>
      </b-row>
      <b-row class="ml-3" v-for="c in t.contacts" :key="c.codeContact">
        <b-col cols="auto">
          <b-form-checkbox
            :disabled="c.disabled"
            :checked="c.selected"
            @input="(value) => (c.selected = value)"
          ></b-form-checkbox>
        </b-col>
        <b-col>
          {{ c.libelle }}
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <b-container fluid>
        <b-row align-h="between">
          <b-col>
            <b-button block pill variant="outline-primary" @click="cancel">
              Annuler
            </b-button>
          </b-col>
          <b-col>
            <b-button block pill variant="primary" @click="validate">
              Valider
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import UtilsService from "../../services/utils.service";
export default {
  name: "CommunicationValidationDialog",
  data: () => ({
    tiers: [],
    resolvePromise: null,
    rejectPromise: null,
  }),
  computed: {
    contacts() {
      return this.tiers.flatMap((t) => t.contacts);
    },
  },
  methods: {
    show(options) {
      let tiers = options?.tiers ?? [];
      tiers.forEach((t) => {
        t.contacts.forEach((c) => {
          c.libelle = `${c.prenom} ${c.nom} (${c.codeContact})`;
          c.selected = !!c.email;
          c.disabled = !c.email;
        });
        t.contacts.sort(UtilsService.sortByStringProperty("libelle"));
        t.libelle = `${t.raisonSociale} (${t.codeTiers})`;
        t.selected = !!t.contacts.find((c) => c.selected);
        t.disabled = !t.contacts.find((c) => !c.disabled);
      });
      tiers.sort(UtilsService.sortByStringProperty("libelle"));
      this.tiers = tiers;
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async validate() {
      this.$refs["modal"].hide();
      this.resolvePromise(this.contacts);
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise([]);
    },
  },
};
</script>
