<template>
  <b-form-group
    :id="`group-${id}`"
    :label="label"
    :label-cols="computedLabelCols['xs']"
    :label-cols-sm="computedLabelCols['sm']"
    :label-cols-md="computedLabelCols['md']"
    :label-cols-lg="computedLabelCols['lg']"
    :label-cols-xl="computedLabelCols['xl']"
    :label-for="`input-${id}`"
  >
    <b-form-tag v-if="value" @remove="select(null)" variant="primary">
      {{ selected ? `${selected.codePostal} ${selected.libelle}` : "INCONNUE" }}
    </b-form-tag>
    <template v-else>
      <b-form-input
        :id="`input-${id}`"
        type="text"
        autocomplete="off"
        v-model="filtre"
        @input="onFilterChanged"
        @blur="popover = false"
      ></b-form-input>
      <b-popover :target="`input-${id}`" triggers="focus" placement="auto">
        <b-list-group>
          <b-list-group-item
            v-for="option in options"
            :key="option.idCommune"
            button
            @click="select(option)"
          >
            {{ option.codePostal }} {{ option.libelle }}
          </b-list-group-item>
        </b-list-group>
      </b-popover>
    </template>
  </b-form-group>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { computed as inputsComputed } from "../../services/inputs.service";
import ReferentielService from "../../services/referentiel.service";
export default {
  name: "SelectCommuneComponent",
  props: {
    // value = valeur du select (ID commune)
    value: Number,
    // label = propriété label du form-group
    label: { type: String, default: "Commune" },
    // labelCols = propriétés label-cols* du form-group
    labelCols: [Object, Number, String],
    // size = taille de la liste d'options (10 par défaut)
    size: { type: Number, default: 10 },
    // pays = code pays pour filtrage (FR par défaut)
    pays: { type: String, default: "FR" },
  },
  watch: {
    value: "load",
  },
  data() {
    return {
      id: uuidv4(),
      filtre: "",
      selected: null,
      options: [],
    };
  },
  computed: {
    ...inputsComputed,
    computedFiltre() {
      return this.filtre?.trim().toLowerCase() ?? "";
    },
    valueFilter() {
      return {
        property: "idCommune",
        operation: "$eq",
        operands: [this.value ?? 0],
      };
    },
    optionsFilter() {
      return {
        operation: "$and",
        operands: [
          { property: "actif", operation: "$eq", operands: [true] },
          { property: "pays.code", operation: "$eq", operands: [this.pays] },
          {
            operation: "$or",
            operands: [
              {
                property: "codePostal",
                operation: "$contains",
                operands: [this.computedFiltre],
                ignoreCase: true,
              },
              {
                property: "libelle",
                operation: "$contains",
                operands: [this.computedFiltre],
                ignoreCase: true,
              },
            ],
          },
        ],
      };
    },
    pageable() {
      return {
        numPage: 0,
        pageSize: this.size,
        sortProperties: ["codePostal", "libelle"],
        sortDirections: ["ASC", "ASC"],
      };
    },
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      // Charger les options correspondant au filtre
      let response = await ReferentielService.getCommunes({
        genericFilters: this.optionsFilter,
        pageable: this.pageable,
      });
      this.options = response.data.content;

      // Charger l'objet correspondant à la valeur courante
      response = await ReferentielService.getCommunes({
        genericFilters: this.valueFilter,
        pageable: this.pageable,
      });
      this.selected = response.data.content[0];
    },
    async onFilterChanged() {
      await this.load();
    },
    select(option) {
      this.$emit("input", option?.idCommune ?? null);
      this.filtre = null;
    },
  },
};
</script>
