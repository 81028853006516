<template>
  <b-container fluid class="widget-frame p-2 p-md-4">
    <b-row align-v="baseline" role="button" @click="toggle">
      <b-col cols="auto">
        <h2>
          <slot name="title-icon">
            <b-icon v-if="titleIcon" :icon="titleIcon"></b-icon>
            <LibreWidgetIcon v-else></LibreWidgetIcon>
          </slot>
        </h2>
      </b-col>
      <b-col class="px-0 px-md-3">
        <h2>
          <slot name="title">{{ title }}</slot>
        </h2>
      </b-col>
      <b-col cols="auto">
        <h2>
          <b-icon-chevron-up v-if="innerVisible"></b-icon-chevron-up>
          <b-icon-chevron-down v-else></b-icon-chevron-down>
        </h2>
      </b-col>
    </b-row>
    <b-collapse
      v-model="innerVisible"
      @show="$emit('show')"
      @shown="$emit('shown')"
      @hide="$emit('hide')"
      @hidden="$emit('hidden')"
    >
      <slot></slot>
    </b-collapse>
  </b-container>
</template>

<script>
import LibreWidgetIcon from "../icons/LibreWidgetIcon.vue";
export default {
  name: "CustomCollapseComponent",
  components: { LibreWidgetIcon },
  props: {
    titleIcon: String,
    title: { type: String, required: true },
    visible: { type: Boolean, default: true },
  },
  data: () => ({
    innerVisible: false,
  }),
  created() {
    this.innerVisible = this.visible ?? !this.$store.getters["affichageMobile"];
  },
  methods: {
    show() {
      this.innerVisible = true;
    },
    toggle() {
      this.innerVisible = !this.innerVisible;
    },
    hide() {
      this.innerVisible = false;
    },
  },
};
</script>
