<template>
  <CritereBase :selected="selected" @select="(value) => $emit('select', value)">
    <CustomSelectComponent
      label="Fonction du contact"
      :labelCols="{ md: 6 }"
      :options="options"
      :value="params[0].valeur"
      @input="(value) => inputParam(0, value)"
    ></CustomSelectComponent>
  </CritereBase>
</template>

<script>
import CritereBase from "./CritereBase.vue";
import CustomSelectComponent from "../../inputs/CustomSelectComponent.vue";
import ReferentielService from "../../../services/referentiel.service";
import UtilsService from "../../../services/utils.service";
import { props, methods } from "./shared";
export default {
  name: "CritereFonction",
  components: { CritereBase, CustomSelectComponent },
  props: { ...props },
  data: () => ({ options: [] }),
  async created() {
    let response = await ReferentielService.getAllFonctions();
    this.options = response.data
      .map((f) => ({
        value: f.code,
        text: f.libelle,
      }))
      .sort(UtilsService.sortByStringProperty("text"));
  },
  methods: { ...methods },
};
</script>
