<template>
  <b-form-group
    :id="`group-${id}`"
    :label="label"
    :label-cols="computedLabelCols['xs']"
    :label-cols-sm="computedLabelCols['sm']"
    :label-cols-md="computedLabelCols['md']"
    :label-cols-lg="computedLabelCols['lg']"
    :label-cols-xl="computedLabelCols['xl']"
    :label-for="`input-${id}`"
  >
    <b-form-tag v-if="value" @remove="select(null)" variant="primary">
      {{ selected ? selected.libelle : "INCONNU" }}
    </b-form-tag>
    <template v-else>
      <b-form-input
        ref="filtre"
        :id="`input-${id}`"
        type="text"
        autocomplete="off"
        v-model="filtre"
        @input="onFilterChanged"
        @blur="
          () => {
            popover = false;
            state = true;
          }
        "
      ></b-form-input>
      <b-form-invalid-feedback
        v-if="state"
        :id="`feedback-${id}`"
        :state="!!value"
      >
        Ce champ est obligatoire
      </b-form-invalid-feedback>
      <b-popover :target="`input-${id}`" triggers="focus" placement="auto">
        <b-list-group>
          <b-list-group-item
            v-for="option in options"
            :key="option.id"
            button
            @click="select(option)"
          >
            {{ option.libelle }}
          </b-list-group-item>
        </b-list-group>
      </b-popover>
    </template>
  </b-form-group>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { computed as inputsComputed } from "../../services/inputs.service";
import CommunicationService from "../../services/communication.service";
export default {
  name: "SelectTemplateMailComponent",
  props: {
    // value = valeur du select (ID)
    value: Number,
    // label = propriété label du form-group
    label: { type: String, default: "Template" },
    // labelCols = propriétés label-cols* du form-group
    labelCols: [Object, Number, String],
    // size = taille de la liste d'options (10 par défaut)
    size: { type: Number, default: 10 },
    // societe = code société pour filtrage
    societe: { type: String, required: true },
  },
  watch: {
    value: "load",
    societe: "load",
  },
  data() {
    return {
      id: uuidv4(),
      filtre: "",
      selected: null,
      options: [],
      state: null,
    };
  },
  computed: {
    ...inputsComputed,
    computedFiltre() {
      return this.filtre?.trim().toLowerCase() ?? "";
    },
    valueFilter() {
      return {
        property: "id",
        operation: "$eq",
        operands: [this.value ?? 0],
      };
    },
    optionsFilter() {
      return {
        property: "libelle",
        operation: "$contains",
        operands: [this.computedFiltre],
        ignoreCase: true,
      };
    },
    pageable() {
      return {
        numPage: 0,
        pageSize: this.size,
        sortProperties: ["libelle"],
        sortDirections: ["ASC"],
      };
    },
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      // Charger les options correspondant au filtre
      let response = await CommunicationService.searchTemplatesMail(
        this.societe,
        {
          genericFilters: this.optionsFilter,
          pageable: this.pageable,
        }
      );
      this.options = response.data.content;

      // Charger l'objet correspondant à la valeur courante
      response = await CommunicationService.searchTemplatesMail(this.societe, {
        genericFilters: this.valueFilter,
        pageable: this.pageable,
      });
      this.selected = response.data.content[0];
    },
    async onFilterChanged() {
      await this.load();
    },
    select(option) {
      this.$emit("input", option?.id ?? null);
      this.filtre = null;
    },
    validate() {
      this.state = true;
      if (!this.value) {
        this.focus();
        throw new Error("Invalid field");
      }
    },
    focus() {
      this.$refs.filtre?.focus();
    },
  },
};
</script>
