<template>
  <CritereBase :selected="selected" @select="(value) => $emit('select', value)">
    <CustomInputNumberComponent
      label="Dans les"
      :labelCols="{ md: '6' }"
      :step="5"
      :value="parseInt(params[0].valeur) || 0"
      @input="(value) => inputParam(0, value)"
    ></CustomInputNumberComponent>
    <SelectCommuneComponent
      label="autour de"
      :labelCols="{ md: '6' }"
      :value="parseInt(params[1].valeur) || null"
      @input="(value) => inputParam(1, value)"
    ></SelectCommuneComponent>
  </CritereBase>
</template>

<script>
import CritereBase from "./CritereBase.vue";
import CustomInputNumberComponent from "../../inputs/CustomInputNumberComponent.vue";
import SelectCommuneComponent from "../../inputs/SelectCommuneComponent.vue";
import { props, methods } from "./shared";
export default {
  name: "CritereDistance",
  components: {
    CritereBase,
    CustomInputNumberComponent,
    SelectCommuneComponent,
  },
  props: { ...props },
  methods: { ...methods },
};
</script>
