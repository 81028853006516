<template>
  <b-row align-v="baseline">
    <b-col cols="auto">
      <b-checkbox
        :checked="selected"
        @input="(value) => $emit('select', value)"
      ></b-checkbox>
    </b-col>
    <b-col>
      <slot></slot>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "CritereBase",
  props: {
    selected: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
