<template>
  <CritereBase :selected="selected" @select="(value) => $emit('select', value)">
    <SelectProduiCerealeComponent
      label="Produit emblavé"
      :labelCols="{ md: '6' }"
      :fetch="true"
      :actif="true"
      :value="params[1].valeur"
      @input="(value) => inputParam(1, value)"
    ></SelectProduiCerealeComponent>
  </CritereBase>
</template>

<script>
import CritereBase from "./CritereBase.vue";
import SelectProduiCerealeComponent from "../../inputs/SelectProduitCerealeComponent.vue";
import { props, methods } from "./shared";
export default {
  name: "CritereProduit",
  components: {
    CritereBase,
    SelectProduiCerealeComponent,
  },
  props: { ...props, campagne: Number },
  watch: {
    campagne: function (newCampagne) {
      this.inputParam(0, newCampagne, false);
    },
  },
  mounted() {
    this.inputParam(0, this.campagne, false);
  },
  methods: { ...methods },
};
</script>
