<template>
  <CritereBase :selected="selected" @select="(value) => $emit('select', value)">
    <SelectEspeceComponent
      label="Espèce emblavée"
      :labelCols="{ md: '6' }"
      :fetch="true"
      :active="true"
      :value="params[1].valeur"
      @input="(value) => inputParam(1, value)"
    ></SelectEspeceComponent>
  </CritereBase>
</template>

<script>
import CritereBase from "./CritereBase.vue";
import SelectEspeceComponent from "../../inputs/SelectEspeceComponent.vue";
import { props, methods } from "./shared";
export default {
  name: "CritereEspece",
  components: { CritereBase, SelectEspeceComponent },
  props: { ...props, campagne: Number },
  watch: {
    campagne: function (newCampagne) {
      this.inputParam(0, newCampagne, false);
    },
  },
  mounted() {
    this.inputParam(0, this.campagne, false);
  },
  methods: { ...methods },
};
</script>
