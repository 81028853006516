<template>
  <CustomSelectComponent
    ref="select"
    :label="label"
    :labelCols="labelCols"
    :readOnly="readOnly"
    :readOnlyValue="readOnlyValue"
    :disabled="disabled"
    :rules="rules"
    :options="options"
    :value="value"
    @input="(value) => $emit('input', value)"
    @change="(value) => $emit('change', value)"
  ></CustomSelectComponent>
</template>

<script>
import CustomSelectComponent from "./CustomSelectComponent.vue";
import ReferentielService from "../../services/referentiel.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "SelectProduitCerealeComponent",
  components: { CustomSelectComponent },
  props: {
    // valeur du select (type sans importance)
    value: undefined,
    // true pour usage de l'API ; sinon usage du store
    fetch: [Boolean, String],
    // code société pour filtrage par blacklist
    societe: String,
    // types de culture pour préfiltrage (format "R1,G4,...")
    typesCulture: String,
    // code espèce pour préfiltrage
    espece: String,
    // true pour les produits actifs uniquement (false par défaut)
    actif: [Boolean, String],
    // propriété label du form-group
    label: {
      type: String,
      default: "Culture",
    },
    // propriété label-cols du form group
    labelCols: [String, Number, Object],
    // true pour affichage lecture seule (false par défaut)
    readOnly: [Boolean, String],
    // true pour griser le controle (false par défaut)
    disabled: [Boolean, String],
    // règles de validation à appliquer (aucune par défaut)
    rules: [String, Object],
  },
  watch: {
    value: "load",
    fetch: "load",
    societe: "load",
    typesCulture: "load",
    espece: "load",
    actif: "load",
  },
  data: () => ({
    options: [],
  }),
  computed: {
    readOnlyValue() {
      return this.$store.getters["ref/produitCereale"](this.value)?.libelle;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      let options = [];

      // Chargement des données selon la source demandée
      if (this.fetch === true || this.fetch === "true") {
        await ReferentielService.getAllProduitsCereale().then(
          (response) => (options = response.data)
        );
      } else {
        options = this.$store.state.ref.produitsCereale;
      }

      // Filtrage selons paramètres fournis
      const typesCulture = this.typesCulture?.split(",") || [];
      options = options.filter(
        (p) =>
          !p.blacklistSocietes.includes(this.societe) &&
          (typesCulture.length === 0 ||
            !p?.typeCulture ||
            typesCulture.includes(p.typeCulture)) &&
          (this.espece || p.codeEspece) === p.codeEspece &&
          ((this.actif !== true && this.actif !== "true") || p.actif)
      );

      // Transformation en options et tri
      options = options
        .map((p) => {
          return {
            value: p.codeProduit,
            text: p.libelle,
          };
        })
        .sort(UtilsService.sortByStringProperty("text"));

      // Ajout de l'option courante désactivée si nécessaire
      if (this.value && !options.find((o) => o.value === this.value)) {
        let produit = this.$store.getters["ref/produitCereale"](this.value);
        options = [
          {
            value: this.value,
            text: produit?.libelle || "INCONNU",
            disabled: true,
          },
          ...options,
        ];
      }

      // Si pas de valeur mais une seule option non nulle, sélection automatique
      if (!this.value && options.length === 1 && options[0].value) {
        await this.$nextTick();
        this.$emit("input", options[0].value);
      }

      this.options = options;
    },
    async validate() {
      await this.$refs.select.validate();
    },
  },
};
</script>
