import UtilsService from "../../../services/utils.service";

export const props = {
  // Application ou non du critère de filtrage
  selected: { type: Boolean, required: true, },
  // Paramètres au format Array<{ id: Number, valeur: String }>
  params: { type: Array, required: true, },
}

export const methods = {
  inputParam(index, value, autoSelect = true) {
    let params = UtilsService.deepCopy(this.params);
    params[index].valeur = value;
    this.$emit("inputParams", params);
    if (autoSelect) {
      this.$emit("select", true);
    }
  }
}
