<template>
  <CritereBase :selected="selected" @select="(value) => $emit('select', value)">
    Tiers dans mon portefeuille
  </CritereBase>
</template>

<script>
import CritereBase from "./CritereBase.vue";
import { props, methods } from "./shared";
export default {
  name: "CriterePortefeuille",
  components: { CritereBase },
  props: { ...props },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    // On charge automatiquement au montage du composant l'email de l'utilisateur connecté !
    // Voir si on autorise les techs à s'adresser au portefeuille des autres techs...
    this.inputParam(0, this.currentUser.email, false);
  },
  methods: { ...methods },
};
</script>
