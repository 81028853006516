<template>
  <span :class="computedVariantClass">{{ formatter.format(value) }}</span>
</template>

<script>
export default {
  name: "MontantComponent",
  /**
   * value = valeur numérique à afficher
   * locale = locale pour le formattage (fr-FR par défaut)
   * options = options pour le formattage ({ minimumFractionDigits: 2, maximumFractionDigits: 2 } par défaut)
   * variantPositive = variante Bootstrap pour les nombres >= 0
   * variantNegative = variante Bootstrap pour les nombres < 0
   */
  props: {
    value: undefined,
    locale: {
      type: String,
      default: "fr-FR",
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    variantPositive: String,
    variantNegative: String,
  },
  computed: {
    computedOptions() {
      return {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...this.options,
      };
    },
    formatter() {
      // Cf. https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
      return new Intl.NumberFormat(this.locale, this.computedOptions);
    },
    computedVariantClass() {
      if (this.variantPositive && this.value >= 0) {
        return `text-${this.variantPositive}`;
      }
      if (this.variantNegative && this.value < 0) {
        return `text-${this.variantNegative}`;
      }
      return "";
    },
  },
};
</script>
