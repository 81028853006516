<template>
  <CustomSelectComponent
    ref="select"
    :label="label"
    :labelCols="labelCols"
    :readOnly="readOnly"
    :readOnlyValue="readOnlyValue"
    :disabled="disabled"
    :rules="rules"
    :options="options"
    :value="value"
    @input="(value) => $emit('input', value)"
    @change="(value) => $emit('change', value)"
  ></CustomSelectComponent>
</template>

<script>
import CustomSelectComponent from "./CustomSelectComponent.vue";
import ReferentielService from "../../services/referentiel.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "SelectEspeceComponent",
  components: { CustomSelectComponent },
  props: {
    // valeur du select (type sans importance)
    value: undefined,
    // true pour usage de l'API ; sinon usage du store
    fetch: [Boolean, String],
    // true pour les espèces actives uniquement (false par défaut)
    active: [Boolean, String],
    // true pour ajouter une option "Toutes" (false par défaut)
    all: [Boolean, String],
    // propriété label du form-group
    label: {
      type: String,
      default: "Espèce",
    },
    // propriété label-cols du form group
    labelCols: [String, Number, Object],
    // true pour affichage lecture seule (false par défaut)
    readOnly: [Boolean, String],
    // true pour griser le controle (false par défaut)
    disabled: [Boolean, String],
    // règles de validation à appliquer (aucune par défaut)
    rules: [String, Object],
  },
  watch: {
    value: "load",
    fetch: "load",
    active: "load",
    all: "load",
  },
  data: () => ({
    options: [],
  }),
  computed: {
    readOnlyValue() {
      return this.$store.getters["ref/espece"](this.value)?.libelle;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      let options = [];

      // Chargement des données selon la source demandée
      if (this.fetch === true || this.fetch === "true") {
        await ReferentielService.getAllEspeces().then(
          (response) => (options = response.data)
        );
      } else {
        options = this.$store.state.ref.especes;
      }

      // Filtrage selons paramètres fournis
      if (this.active === true || this.active === "true") {
        options = options.filter((e) => e.active);
      }

      // Transformation en options et tri
      options = options
        .map((e) => {
          return {
            value: e.codeEspece,
            text: e.libelle,
          };
        })
        .sort(UtilsService.sortByStringProperty("text"));

      // Ajout de l'option courante désactivée si nécessaire
      if (this.value && !options.find((o) => o.value === this.value)) {
        let espece = this.$store.getters["ref/espece"](this.value);
        options = [
          {
            value: this.value,
            text: espece?.libelle || "INCONNUE",
            disabled: true,
          },
          ...options,
        ];
      }

      // Ajout de l'option Toutes si nécessaire
      if (this.all === true || this.all === "true") {
        options = [{ value: null, text: "Toutes" }, ...options];
      }

      // SINON si pas de valeur mais une seule option non nulle, sélection automatique
      else if (!this.value && options.length === 1 && options[0].value) {
        await this.$nextTick();
        this.$emit("input", options[0].value);
      }

      this.options = options;
    },
    async validate() {
      await this.$refs.select.validate();
    },
  },
};
</script>
