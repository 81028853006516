import { apiPwfb } from "../api-pwfb";

const CritereCommunication = {
  TYPE_TIERS: { name: "TYPE_TIERS", component: "CritereTypeTiers", defaultValue: { selected: false, parametres: ["A"], }, },
  TIERS_PORTEFEUILLE: { name: "TIERS_PORTEFEUILLE", component: "CriterePortefeuille", defaultValue: { selected: false, parametres: [null], }, },
  TIERS_APPORTEUR: { name: "TIERS_APPORTEUR", component: "CritereApporteur", defaultValue: { selected: false, parametres: [], }, },
  FONCTION_CONTACT: { name: "FONCTION_CONTACT", component: "CritereFonction", defaultValue: { selected: false, parametres: [null], }, },
  DATE_ADHESION: { name: "DATE_ADHESION", component: "CritereDateAdhesion", defaultValue: { selected: false, parametres: ["1900-01-01", "2100-01-01"], }, },
  DEPARTEMENT: { name: "DEPARTEMENT", component: "CritereDepartement", defaultValue: { selected: false, parametres: [null], }, },
  DISTANCE: { name: "DISTANCE", component: "CritereDistance", defaultValue: { selected: false, parametres: ["50", null], }, },
  ESPECE: { name: "ESPECE", component: "CritereEspece", defaultValue: { selected: false, parametres: [null, null], }, },
  PRODUIT: { name: "PRODUIT", component: "CritereProduit", defaultValue: { selected: false, parametres: [null, null], }, },
  VARIETE: { name: "VARIETE", component: "CritereVariete", defaultValue: { selected: false, parametres: [null, null], }, },
  SEMIS: { name: "SEMIS", component: "CritereSemis", defaultValue: { selected: false, parametres: [null, "AUTOMNE"], }, },
  RECOLTE: { name: "RECOLTE", component: "CritereRecolte", defaultValue: { selected: false, parametres: [null, "ETE"], }, },
}

const emptyCommunication = (codeSociete) => ({
  id: null,
  codeSociete,
  filtres: [],
  contacts: [],
  copyTo: "",
  idTemplate: null,
  objet: "",
  contenus: [],
});

const emptyFiltre = (codeCritere) => ({ codeCritere, parametres: [] });

const emptyFiltreParam = (valeur) => ({ id: null, valeur });

const emptyContact = (codeContact) => ({ codeContact, selected: true });

const emptyContenu = () => ({ id: null, contenu: "" });

class CommunicationService {
  searchContacts(codeSociete, payload) {
    return apiPwfb.post(`/communication/${codeSociete}/contacts/search`, payload);
  }
  searchTemplatesMail(codeSociete, payload) {
    return apiPwfb.post(`/communication/${codeSociete}/templates-mail/search`, payload);
  }
  getTemplateMail(idTemplate) {
    return apiPwfb.get(`/communication/template-mail/${idTemplate}`);
  }
  searchTemplates(codeSociete, payload) {
    return apiPwfb.post(`/communication/${codeSociete}/templates/search`, payload);
  }
  searchHistoriques(codeSociete, payload) {
    return apiPwfb.post(`/communication/${codeSociete}/historiques/search`, payload);
  }
  createUpdateTemplate(payload) {
    if (payload.id) {
      return apiPwfb.post("/communication/template/update", payload);
    } else {
      return apiPwfb.post("/communication/template/create", payload);
    }
  }
  deleteTemplate(idTemplate) {
    return apiPwfb.get(`/communication/template/${idTemplate}/delete`);
  }
  getCommunication(idCommunication) {
    return apiPwfb.get(`/communication/${idCommunication}/get`);
  }
  sendCommunication(payload) {
    return apiPwfb.post("/communication/send", payload);
  }
}

const methods = {
  emptyCommunication,
  emptyFiltre,
  emptyFiltreParam,
  emptyContact,
  emptyContenu,
};

export default new CommunicationService();
export { CritereCommunication, methods };
