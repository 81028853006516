<template>
  <svg
    width="46"
    height="42"
    viewBox="0 0 46 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="21.1135" r="20" fill="#ACD1C6" />
    <path
      d="M14.7138 21.0494H14.2138V21.5494V23.7747V24.2747H14.7138H19.5876H20.0876V23.7747V21.5494V21.0494H19.5876H14.7138ZM13.0476 20.9915C13.0476 20.3341 13.5635 19.8242 14.1714 19.8242H20.13C20.7373 19.8242 21.2538 20.337 21.2538 20.9915V24.3326C21.2538 24.99 20.7379 25.4999 20.13 25.4999H14.1714C13.5641 25.4999 13.0476 24.9872 13.0476 24.3326V20.9915Z"
      fill="#ACD1C6"
      stroke="#054643"
    />
    <path
      d="M7.88771 22.8827L6.31813 18.5562L29.8846 0.630706C34.5148 4.30203 37.4999 10.0622 37.4999 16.5428C37.4999 17.2265 37.4667 17.902 37.4019 18.5678L7.88771 22.8827Z"
      fill="white"
      stroke="#054643"
    />
    <path
      d="M4.53901 19.7668L8.67871 15.5921L10.4231 20.461L10.4231 20.461L11.8823 24.5349L5.48616 24.9172L4.53901 19.7668Z"
      fill="#ACD1C6"
      stroke="#054643"
    />
    <path
      d="M33.9999 1.10821C39.5947 4.96888 41.1781 8.01742 40.9999 15.1082"
      stroke="#054643"
    />
    <path
      d="M38.0002 1.14335C42 4 43.4826 7 43.4826 12.1081"
      stroke="#054643"
    />
  </svg>
</template>

<script>
export default {
  name: "LibreWidgetIcon",
};
</script>
