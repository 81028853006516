<template>
  <CritereBase :selected="selected" @select="(value) => $emit('select', value)">
    <CustomSelectComponent
      label="Culture non récoltée"
      :labelCols="{ md: '6' }"
      :options="options"
      :value="params[1].valeur"
      @input="(value) => inputParam(1, value)"
    ></CustomSelectComponent>
  </CritereBase>
</template>

<script>
import CritereBase from "./CritereBase.vue";
import CustomSelectComponent from "../../inputs/CustomSelectComponent.vue";
import ReferentielService from "../../../services/referentiel.service";
import { props, methods } from "./shared";
export default {
  name: "CritereRecolte",
  components: { CritereBase, CustomSelectComponent },
  props: { ...props, campagne: Number },
  watch: {
    campagne: function (newCampagne) {
      this.inputParam(0, newCampagne, false);
    },
  },
  data: () => ({ options: [] }),
  async created() {
    let response = await ReferentielService.getAllPeriodesMoisson();
    this.options = response.data.map((p) => ({ value: p, text: p }));
  },
  mounted() {
    this.inputParam(0, this.campagne, false);
  },
  methods: { ...methods },
};
</script>
